import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import {
  DocumentReference,
  FirestoreDataConverter,
  Timestamp,
  getFirestore,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyD2_EZsmUiPQrrEaoQZCdwlRxXrs1u8mxI",
  authDomain: "valdisere-calendar.firebaseapp.com",
  projectId: "valdisere-calendar",
  storageBucket: "valdisere-calendar.appspot.com",
  messagingSenderId: "35689760344",
  appId: "1:35689760344:web:5374ddff87dca53856ec2d",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const db = getFirestore(app);

export enum Flat {
  "Fourteen" = "14",
  "ThirtyOne" = "31",
  "ThirtyThree" = "33",
}

export const flats = [
  {
    group: "1er étage",
    items: [{ label: "14", value: Flat.Fourteen }],
  },
  {
    group: "3ème étage",
    items: [
      { label: "31 (Studio)", value: Flat.ThirtyOne },
      { label: "33 (Grand appart)", value: Flat.ThirtyThree },
    ],
  },
];

export type Event = {
  id?: string;
  ref?: DocumentReference<Event>;
  createdAt?: Timestamp;
  createdBy?: string;
  updatedAt?: Timestamp;
  updatedBy?: string;
  deletedAt?: Timestamp;
  deletedBy?: string;
  startDate?: string;
  endDate?: string;
  title?: string;
  adults?: number;
  children?: number;
  flats?: Flat[];
  history?: Event[];
};

export const firestoreConverter: FirestoreDataConverter<any> = {
  toFirestore(data) {
    const { id: _id, ref: _ref, ...rest } = data;
    return rest;
  },
  fromFirestore(snapshot, options) {
    return { ...snapshot.data(options), id: snapshot.id, ref: snapshot.ref };
  },
};
